<template>
<div class="reserveList">
  <van-icon class="fh" @click="$router.back(-1)" name="arrow-left" />
  <div class="top">
    <p>预约列表</p>
    <div>
      <span>病历号：</span>
      <input :disabled="!isbj" v-model="blh" type="text">
      <span v-if="!isbj" @click="isbj = true">编辑</span>
      <span v-if="isbj" @click="saveBlh">保存</span>
    </div>
  </div>
  <div class="time">
    <div class="timeBox">
      <div v-for="(item, idx) in timeList" @click="xzTime(idx)">
        <span :class="timeIdx == idx ? 'xz' : ''">{{item}}</span>
        <p v-if="timeIdx == idx"></p>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="box" v-for="item in ysList" @click="goEx(item)">
      <img :src="'http://zsjstaticfile.oss-cn-shenzhen.aliyuncs.com/'+item.ExpertstrImgUrl" alt="">
      <div class="right">
        <h3>{{item.ExpertName}}</h3>
        <div class="yszc">{{item.ExpertWorkTitle}}</div>
        <div style="width: 60vw">{{item.ExpertMajor}}</div>
        <div class="yspj">
          <span>评价</span>
          <span>999</span>
          <span style="padding-left: 15px">咨询数</span>
          <span>999</span>
          <span style="padding-left: 15px">推荐数</span>
          <span>5.0</span>
        </div>
      </div>
    </div>
  </div>

  <van-overlay :show="show">
    <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;">
      <van-loading size="24px" color="#ffffff" >加载中...</van-loading>
    </div>
  </van-overlay>
</div>
</template>

<script>
function getFutureDates(numDays) {
  const futureDates = [];
  const today = new Date();

  for (let i = 1; i <= numDays; i++) {
    const futureDate = new Date(today);
    futureDate.setDate(today.getDate() + i);

    const year = futureDate.getFullYear();
    const month = (futureDate.getMonth() + 1).toString().padStart(2, '0');
    const day = futureDate.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    futureDates.push(formattedDate);
  }

  return futureDates;
}
export default {
  name: "reserveList",
  data() {
    return {
      timeList: [],
      timeIdx: 0,
      shopId: '',
      ysList: [],
      blh: '',
      isbj: false,
      blhData: '',
      show: false
    }
  },
  created() {
    this.shopId = this.$route.query.shopId
    this.timeList = [...getFutureDates(30)]
    this.getmzh()
    this.getYs(this.timeList[0])
  },
  methods: {
    xzTime(idx) {
      this.timeIdx = idx
      this.getYs(this.timeList[idx])
    },

    getYs(time) {
      this.show = true
      this._http2.post('https://interface2.visumall.com/Optometrist/Schedule.ashx?cmd=GetExpertListInSchedules', {
        "token":{
          "TimeSpan":1517328686398,
          "sToken":"89090FDE8B6B5247015F6BBB1D85DD38"
        },
        "SearchCondition":{
          "IndexMin":0,
          "IndexMax":0,
          "PageIndex":1,
          "PageSize":20,
          "ScheduleShopId":  this.shopId,
          "ScheduleDateStart": time,
          "ScheduleDateEnd": time
        },
        "ReturnInfo":{"Expert":{}}
      })
      .then(res => {
        this.show = false
        if (res.GetListResult && res.GetListResult.length) {
          this.ysList = [...res.GetListResult]
        }else {
          this.ysList = []
        }
      })
    },

    getmzh() {
      this._http2.post('https://interface2.visumall.com/Customer/CustomerShopRelation.ashx?cmd=Get', {
        "token":{
          "TimeSpan":1566827733,
          "sToken":"768DCAFFF869CD61372790C2738012EC"
        },
        "SearchCondition":{
          "CSRShopId": this.shopId,
          "CSRCustomerId": this.$store.state.Base.user.CustomerId,
          "CSRType":"病历号"
        }
      })
      .then(res => {
        if (res.GetResult) {
          this.blh = res.GetResult.CSRValue
          this.blhData = JSON.parse(JSON.stringify(res.GetResult))
        }
      })
    },

    saveBlh() {
      this.isbj = false
      if (this.blhData) {
        this.blhData.CSRValue = this.blh
        this._http2.post('https://interface2.visumall.com/Customer/CustomerShopRelation.ashx?cmd=Update', {
          "token":{"sToken":"8BED23DE028E0E9966B8CE9E8F77DB89","TimeSpan":"1535942069232"},
          "o": this.blhData
        })
        .then(res => {
          if (res.UpdateResult) {
            this.$dialog.alert({message: '修改成功', theme: 'round-button'})
          }else  {
            this.$dialog.alert({message: '修改失败,请稍后再试', theme: 'round-button'})
          }
        })
      }else {
        this._http2.post('https://interface2.visumall.com/Customer/CustomerShopRelation.ashx?cmd=Insert', {
          "token":{"sToken":"8BED23DE028E0E9966B8CE9E8F77DB89","TimeSpan":"1535942069232"},
          "o":{
            "CSRCustomerId":this.$store.state.Base.user.CustomerId,
            "CSRShopId": this.shopId,
            "CSRType": "病历号",
            "CSRValue": this.blh
          }})
        .then(res => {
          if (res.InsertResult) {
            this.$dialog.alert({message: '添加成功', theme: 'round-button'})
          }else  {
            this.$dialog.alert({message: '添加失败,请稍后再试', theme: 'round-button'})
          }
        })
      }
    },

    goEx(item) {
      this.$store.commit('Base/upExpert', item)
      this.$router.push({path: '/appointmentHome/appointmentDetails', query: {time: this.timeList[this.timeIdx], shopId: this.shopId}})
    }
  }
}
</script>

<style scoped lang="scss">
.reserveList {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #FFF1DF;
}
.top {
  background: #BCE2FF;
  width: 100vw;
  position: relative;
  padding-bottom: 10px;
  font-size: 15px;
  p {
    color: #fff;
    text-align: center;
    font-size: 19px;
    padding: 17px 0;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      background: #ffffff;
      padding: 8px 5px;
      border-radius: 5px;
      width: 45vw;
      border: none;
    }
    span:nth-child(3) {
      padding: 6px;
      border-radius: 5px;
      background: #6ec69e;
      color: #fff;
      margin-left: 10px;
    }
  }
}
.time {
  width: 100vw;
  overflow: hidden;
  overflow-x: auto;
  font-size: 15px;
  background: #F4F4F4;
  color: #797979;
  .timeBox {
    width: 2800px;
    display: flex;
    justify-content: space-between;
    padding: 18px 15px;
    font-size: 15px;
    div {
      position: relative;
      p {
        position: absolute;
        width: 50px;
        background: #0091FF;
        height: 2px;
        left: 50%;
        transform: translateX(-50%);
        bottom: -8px;
      }
    }
    .xz {color: #0091FF}
  }
}
.content {
  width: 100vw;
  height: calc(100vh - 150px);
  overflow-y: auto;
  .box {
    width: 100vw;
    background: #fff;
    margin-bottom: 20px;
    overflow: hidden;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      width: 77px;
      height: 77px;
      border-radius: 50%;
      margin-right: 15px;
    }
    .right {
      .yszc {
        display: inline-block;
        background: #FFCCB7;
        color: #FF592D;
        border-radius: 10px;
        padding: 6px 15px;
        margin: 8px 0;
        max-width: 8em;
        text-align: center;
      }
      .yspj {
        color: #7F7F7F;
        padding: 12px 0;
        span {padding-right: 5px}
      }
    }
  }
}
.fh {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
  font-size: 20px;
}
</style>
